export default {
  v: '5.9.4',
  fr: 25,
  ip: 0,
  op: 94,
  w: 200,
  h: 200,
  ddd: 0,
  assets: [
    {
      id: 'comp_0',
      fr: 25,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 3,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [1387.191, 559.819, 0], ix: 2, l: 2 },
            a: { a: 0, k: [396.753, 38.981, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.8, 0.8, 0.8], y: [0, 0, 0] },
                  t: 90,
                  s: [304, 304, 100]
                },
                { t: 110, s: [0, 0, 100] }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 0,
          op: 1029,
          st: 29,
          ct: 1,
          bm: 0
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          parent: 1,
          td: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [396.6, 38.95, 0], ix: 2, l: 2 },
            a: { a: 0, k: [424.727, 20.727, 0], ix: 1, l: 2 },
            s: { a: 0, k: [31.237, 31.237, 100], ix: 6, l: 2 }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [155.453, 155.453], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  hd: false
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.564705882353, 0.760784313725, 0.972549019608, 1],
                    ix: 3
                  },
                  o: { a: 0, k: 0, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.870588295133, 0.984313785329, 0.509803921569, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [424.727, 20.727], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 }
                }
              ],
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              hd: false
            }
          ],
          ip: 0,
          op: 1029,
          st: 0,
          ct: 1,
          bm: 0
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          parent: 1,
          tt: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.2, y: 1 },
                  o: { x: 0.8, y: 0 },
                  t: 90,
                  s: [424.89, 66.582, 0],
                  to: [-4.715, -4.605, 0],
                  ti: [4.715, 4.605, 0]
                },
                { t: 110, s: [396.6, 38.95, 0] }
              ],
              ix: 2,
              l: 2
            },
            a: { a: 0, k: [424.727, 20.727, 0], ix: 1, l: 2 },
            s: { a: 0, k: [32.237, 32.237, 100], ix: 6, l: 2 }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [155.453, 155.453], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.286274509804, 0.415686304429, 0.988235353956, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [424.727, 20.727], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 }
                }
              ],
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              hd: false
            }
          ],
          ip: 0,
          op: 1029,
          st: 0,
          ct: 1,
          bm: 0
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          parent: 1,
          td: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [396.6, 38.95, 0], ix: 2, l: 2 },
            a: { a: 0, k: [424.727, 20.727, 0], ix: 1, l: 2 },
            s: { a: 0, k: [31.237, 31.237, 100], ix: 6, l: 2 }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [155.453, 155.453], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  hd: false
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.564705882353, 0.760784313725, 0.972549019608, 1],
                    ix: 3
                  },
                  o: { a: 0, k: 0, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.870588295133, 0.984313785329, 0.509803921569, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [424.727, 20.727], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 }
                }
              ],
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              hd: false
            }
          ],
          ip: 0,
          op: 1029,
          st: 0,
          ct: 1,
          bm: 0
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          parent: 1,
          tt: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.2, y: 1 },
                  o: { x: 0.8, y: 0 },
                  t: 90,
                  s: [368.969, 10.825, 0],
                  to: [4.605, 4.688, 0],
                  ti: [-4.605, -4.688, 0]
                },
                { t: 110, s: [396.6, 38.95, 0] }
              ],
              ix: 2,
              l: 2
            },
            a: { a: 0, k: [424.727, 20.727, 0], ix: 1, l: 2 },
            s: { a: 0, k: [32.237, 32.237, 100], ix: 6, l: 2 }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [155.453, 155.453], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.286274509804, 0.415686304429, 0.988235353956, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [424.727, 20.727], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 }
                }
              ],
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              hd: false
            }
          ],
          ip: 0,
          op: 1029,
          st: 0,
          ct: 1,
          bm: 0
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          parent: 1,
          td: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [396.6, 38.95, 0], ix: 2, l: 2 },
            a: { a: 0, k: [424.727, 20.727, 0], ix: 1, l: 2 },
            s: { a: 0, k: [31.237, 31.237, 100], ix: 6, l: 2 }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [155.453, 155.453], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  hd: false
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.564705882353, 0.760784313725, 0.972549019608, 1],
                    ix: 3
                  },
                  o: { a: 0, k: 0, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.870588295133, 0.984313785329, 0.509803921569, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [424.727, 20.727], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 }
                }
              ],
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              hd: false
            }
          ],
          ip: 0,
          op: 1029,
          st: 0,
          ct: 1,
          bm: 0
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          parent: 1,
          tt: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.2, y: 1 },
                  o: { x: 0.8, y: 0 },
                  t: 90,
                  s: [368.146, 67.898, 0],
                  to: [1.101, -1.12, 0],
                  ti: [-3.642, 3.705, 0]
                },
                { t: 110, s: [396.6, 38.95, 0] }
              ],
              ix: 2,
              l: 2
            },
            a: { a: 0, k: [424.727, 20.727, 0], ix: 1, l: 2 },
            s: { a: 0, k: [32.237, 32.237, 100], ix: 6, l: 2 }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [155.453, 155.453], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.286274509804, 0.415686304429, 0.988235353956, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [424.727, 20.727], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 }
                }
              ],
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              hd: false
            }
          ],
          ip: 0,
          op: 1029,
          st: 0,
          ct: 1,
          bm: 0
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          parent: 1,
          td: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [396.6, 38.95, 0], ix: 2, l: 2 },
            a: { a: 0, k: [424.727, 20.727, 0], ix: 1, l: 2 },
            s: { a: 0, k: [31.237, 31.237, 100], ix: 6, l: 2 }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [155.453, 155.453], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  hd: false
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.564705882353, 0.760784313725, 0.972549019608, 1],
                    ix: 3
                  },
                  o: { a: 0, k: 0, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.870588295133, 0.984313785329, 0.509803921569, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [424.727, 20.727], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 }
                }
              ],
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              hd: false
            }
          ],
          ip: 0,
          op: 1029,
          st: 0,
          ct: 1,
          bm: 0
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          parent: 1,
          tt: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.2, y: 1 },
                  o: { x: 0.8, y: 0 },
                  t: 90,
                  s: [424.89, 10.661, 0],
                  to: [-4.715, 4.715, 0],
                  ti: [4.715, -4.715, 0]
                },
                { t: 110, s: [396.6, 38.95, 0] }
              ],
              ix: 2,
              l: 2
            },
            a: { a: 0, k: [424.727, 20.727, 0], ix: 1, l: 2 },
            s: { a: 0, k: [32.237, 32.237, 100], ix: 6, l: 2 }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [155.453, 155.453], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.286274509804, 0.415686304429, 0.988235353956, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [424.727, 20.727], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 }
                }
              ],
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              hd: false
            }
          ],
          ip: 0,
          op: 1029,
          st: 0,
          ct: 1,
          bm: 0
        },
        {
          ddd: 0,
          ind: 10,
          ty: 4,
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 1, y: 0 },
                  t: 97,
                  s: [396.753, 38.981, 0],
                  to: [0.384, -0.877, 0],
                  ti: [-0.384, 0.877, 0]
                },
                { t: 117, s: [399.056, 33.718, 0] }
              ],
              ix: 2,
              l: 2
            },
            a: { a: 0, k: [396.753, 38.981, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.232, 0.038],
                        [3.738, 3.728],
                        [0.837, 5.213],
                        [-0.067, 0.227],
                        [-0.159, 0.175],
                        [-0.219, 0.088],
                        [-0.236, -0.015],
                        [-0.842, -11.834],
                        [0.088, -0.218],
                        [0.175, -0.158],
                        [0.226, -0.066]
                      ],
                      o: [
                        [-5.215, -0.824],
                        [-3.737, -3.729],
                        [-0.038, -0.233],
                        [0.067, -0.226],
                        [0.159, -0.175],
                        [0.219, -0.089],
                        [11.748, 0.824],
                        [0.016, 0.234],
                        [-0.088, 0.218],
                        [-0.174, 0.158],
                        [-0.225, 0.066]
                      ],
                      v: [
                        [9.511, 11.279],
                        [-4.263, 4.275],
                        [-11.299, -9.481],
                        [-11.256, -10.18],
                        [-10.913, -10.79],
                        [-10.339, -11.19],
                        [-9.648, -11.302],
                        [11.321, 9.636],
                        [11.211, 10.324],
                        [10.812, 10.895],
                        [10.206, 11.236]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.286274509804, 0.415686304429, 0.988235353956, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [383.369, 52.398], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 }
                }
              ],
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              hd: false
            }
          ],
          ip: 0,
          op: 186,
          st: 29,
          ct: 1,
          bm: 0
        },
        {
          ddd: 0,
          ind: 11,
          ty: 4,
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 1, y: 0 },
                  t: 97,
                  s: [396.753, 38.981, 0],
                  to: [-0.795, -0.905, 0],
                  ti: [0.795, 0.905, 0]
                },
                { t: 117, s: [391.983, 33.553, 0] }
              ],
              ix: 2,
              l: 2
            },
            a: { a: 0, k: [396.753, 38.981, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.04, -0.234],
                        [3.738, -3.731],
                        [5.216, -0.82],
                        [0.227, 0.067],
                        [0.175, 0.159],
                        [0.088, 0.22],
                        [-0.017, 0.236],
                        [-11.839, 0.828],
                        [-0.22, -0.088],
                        [-0.16, -0.176],
                        [-0.067, -0.228]
                      ],
                      o: [
                        [-0.831, 5.215],
                        [-3.738, 3.73],
                        [-0.234, 0.04],
                        [-0.227, -0.066],
                        [-0.176, -0.159],
                        [-0.088, -0.219],
                        [0.837, -11.834],
                        [0.237, -0.017],
                        [0.221, 0.088],
                        [0.16, 0.176],
                        [0.066, 0.228]
                      ],
                      v: [
                        [11.302, -9.481],
                        [4.273, 4.282],
                        [-9.503, 11.283],
                        [-10.205, 11.243],
                        [-10.816, 10.901],
                        [-11.217, 10.324],
                        [-11.325, 9.631],
                        [9.644, -11.306],
                        [10.34, -11.198],
                        [10.918, -10.797],
                        [11.262, -10.183]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.286274509804, 0.415686304429, 0.988235353956, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [410.13, 52.394], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 }
                }
              ],
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              hd: false
            }
          ],
          ip: 0,
          op: 186,
          st: 29,
          ct: 1,
          bm: 0
        },
        {
          ddd: 0,
          ind: 12,
          ty: 4,
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 1, y: 0 },
                  t: 97,
                  s: [396.753, 38.981, 0],
                  to: [-0.795, 0.302, 0],
                  ti: [0.795, -0.302, 0]
                },
                { t: 117, s: [391.983, 40.79, 0] }
              ],
              ix: 2,
              l: 2
            },
            a: { a: 0, k: [396.753, 38.981, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.233, -0.038],
                        [-3.744, -3.753],
                        [-0.814, -5.238],
                        [0.067, -0.227],
                        [0.159, -0.176],
                        [0.219, -0.088],
                        [0.236, 0.017],
                        [0.796, 11.803],
                        [-0.087, 0.22],
                        [-0.175, 0.16],
                        [-0.227, 0.067]
                      ],
                      o: [
                        [5.236, 0.827],
                        [3.744, 3.753],
                        [0.039, 0.233],
                        [-0.067, 0.227],
                        [-0.159, 0.175],
                        [-0.22, 0.088],
                        [-11.88, -0.823],
                        [-0.018, -0.236],
                        [0.088, -0.22],
                        [0.174, -0.16],
                        [0.227, -0.068]
                      ],
                      v: [
                        [-9.521, -11.341],
                        [4.298, -4.294],
                        [11.311, 9.542],
                        [11.269, 10.241],
                        [10.927, 10.853],
                        [10.352, 11.253],
                        [9.659, 11.362],
                        [-11.332, -9.68],
                        [-11.227, -10.372],
                        [-10.83, -10.949],
                        [-10.221, -11.295]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.286274509804, 0.415686304429, 0.988235353956, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [410.143, 25.633], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 }
                }
              ],
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              hd: false
            }
          ],
          ip: 0,
          op: 186,
          st: 29,
          ct: 1,
          bm: 0
        },
        {
          ddd: 0,
          ind: 13,
          ty: 4,
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 1, y: 0 },
                  t: 97,
                  s: [396.753, 38.981, 0],
                  to: [0.411, 0.274, 0],
                  ti: [-0.411, -0.274, 0]
                },
                { t: 117, s: [399.22, 40.626, 0] }
              ],
              ix: 2,
              l: 2
            },
            a: { a: 0, k: [396.753, 38.981, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.017, -0.236],
                        [11.793, -0.824],
                        [0.219, 0.088],
                        [0.159, 0.175],
                        [0.066, 0.227],
                        [-0.039, 0.234],
                        [-3.744, 3.753],
                        [-5.237, 0.823],
                        [-0.227, -0.067],
                        [-0.175, -0.16],
                        [-0.088, -0.22]
                      ],
                      o: [
                        [-0.805, 11.802],
                        [-0.236, 0.016],
                        [-0.22, -0.089],
                        [-0.159, -0.175],
                        [-0.067, -0.227],
                        [0.811, -5.239],
                        [3.745, -3.753],
                        [0.234, -0.038],
                        [0.227, 0.067],
                        [0.175, 0.159],
                        [0.088, 0.219]
                      ],
                      v: [
                        [11.335, -9.682],
                        [-9.661, 11.361],
                        [-10.353, 11.251],
                        [-10.929, 10.85],
                        [-11.271, 10.239],
                        [-11.313, 9.539],
                        [-4.303, -4.298],
                        [9.519, -11.339],
                        [10.219, -11.295],
                        [10.829, -10.949],
                        [11.228, -10.374]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.286274509804, 0.415686304429, 0.988235353956, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [383.365, 25.621], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 }
                }
              ],
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              hd: false
            }
          ],
          ip: 0,
          op: 186,
          st: 29,
          ct: 1,
          bm: 0
        }
      ]
    },
    {
      id: 'comp_1',
      fr: 25,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 3,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [1387.191, 559.819, 0], ix: 2, l: 2 },
            a: { a: 0, k: [396.753, 38.981, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.8, 0.8, 0.8], y: [0, 0, 0] },
                  t: 90,
                  s: [0, 0, 100]
                },
                { t: 110, s: [304, 304, 100] }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          ip: 0,
          op: 1029,
          st: 29,
          ct: 1,
          bm: 0
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          parent: 1,
          td: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [396.6, 38.95, 0], ix: 2, l: 2 },
            a: { a: 0, k: [424.727, 20.727, 0], ix: 1, l: 2 },
            s: { a: 0, k: [31.237, 31.237, 100], ix: 6, l: 2 }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [155.453, 155.453], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  hd: false
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.564705882353, 0.760784313725, 0.972549019608, 1],
                    ix: 3
                  },
                  o: { a: 0, k: 0, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.870588295133, 0.984313785329, 0.509803921569, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [424.727, 20.727], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 }
                }
              ],
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              hd: false
            }
          ],
          ip: 0,
          op: 1029,
          st: 0,
          ct: 1,
          bm: 0
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          parent: 1,
          tt: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.2, y: 1 },
                  o: { x: 0.8, y: 0 },
                  t: 90,
                  s: [396.6, 38.95, 0],
                  to: [4.715, 4.605, 0],
                  ti: [-4.715, -4.605, 0]
                },
                { t: 110, s: [424.89, 66.582, 0] }
              ],
              ix: 2,
              l: 2
            },
            a: { a: 0, k: [424.727, 20.727, 0], ix: 1, l: 2 },
            s: { a: 0, k: [32.237, 32.237, 100], ix: 6, l: 2 }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [155.453, 155.453], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.286274509804, 0.415686304429, 0.988235353956, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [424.727, 20.727], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 }
                }
              ],
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              hd: false
            }
          ],
          ip: 0,
          op: 1029,
          st: 0,
          ct: 1,
          bm: 0
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          parent: 1,
          td: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [396.6, 38.95, 0], ix: 2, l: 2 },
            a: { a: 0, k: [424.727, 20.727, 0], ix: 1, l: 2 },
            s: { a: 0, k: [31.237, 31.237, 100], ix: 6, l: 2 }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [155.453, 155.453], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  hd: false
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.564705882353, 0.760784313725, 0.972549019608, 1],
                    ix: 3
                  },
                  o: { a: 0, k: 0, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.870588295133, 0.984313785329, 0.509803921569, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [424.727, 20.727], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 }
                }
              ],
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              hd: false
            }
          ],
          ip: 0,
          op: 1029,
          st: 0,
          ct: 1,
          bm: 0
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          parent: 1,
          tt: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.2, y: 1 },
                  o: { x: 0.8, y: 0 },
                  t: 90,
                  s: [396.6, 38.95, 0],
                  to: [-4.605, -4.688, 0],
                  ti: [4.605, 4.688, 0]
                },
                { t: 110, s: [368.969, 10.825, 0] }
              ],
              ix: 2,
              l: 2
            },
            a: { a: 0, k: [424.727, 20.727, 0], ix: 1, l: 2 },
            s: { a: 0, k: [32.237, 32.237, 100], ix: 6, l: 2 }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [155.453, 155.453], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.286274509804, 0.415686304429, 0.988235353956, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [424.727, 20.727], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 }
                }
              ],
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              hd: false
            }
          ],
          ip: 0,
          op: 1029,
          st: 0,
          ct: 1,
          bm: 0
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          parent: 1,
          td: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [396.6, 38.95, 0], ix: 2, l: 2 },
            a: { a: 0, k: [424.727, 20.727, 0], ix: 1, l: 2 },
            s: { a: 0, k: [31.237, 31.237, 100], ix: 6, l: 2 }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [155.453, 155.453], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  hd: false
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.564705882353, 0.760784313725, 0.972549019608, 1],
                    ix: 3
                  },
                  o: { a: 0, k: 0, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.870588295133, 0.984313785329, 0.509803921569, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [424.727, 20.727], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 }
                }
              ],
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              hd: false
            }
          ],
          ip: 0,
          op: 1029,
          st: 0,
          ct: 1,
          bm: 0
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          parent: 1,
          tt: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.2, y: 1 },
                  o: { x: 0.8, y: 0 },
                  t: 90,
                  s: [396.6, 38.95, 0],
                  to: [-3.642, 3.705, 0],
                  ti: [1.101, -1.12, 0]
                },
                { t: 110, s: [368.146, 67.898, 0] }
              ],
              ix: 2,
              l: 2
            },
            a: { a: 0, k: [424.727, 20.727, 0], ix: 1, l: 2 },
            s: { a: 0, k: [32.237, 32.237, 100], ix: 6, l: 2 }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [155.453, 155.453], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.286274509804, 0.415686304429, 0.988235353956, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [424.727, 20.727], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 }
                }
              ],
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              hd: false
            }
          ],
          ip: 0,
          op: 1029,
          st: 0,
          ct: 1,
          bm: 0
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          parent: 1,
          td: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [396.6, 38.95, 0], ix: 2, l: 2 },
            a: { a: 0, k: [424.727, 20.727, 0], ix: 1, l: 2 },
            s: { a: 0, k: [31.237, 31.237, 100], ix: 6, l: 2 }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [155.453, 155.453], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  hd: false
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.564705882353, 0.760784313725, 0.972549019608, 1],
                    ix: 3
                  },
                  o: { a: 0, k: 0, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.870588295133, 0.984313785329, 0.509803921569, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [424.727, 20.727], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 }
                }
              ],
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              hd: false
            }
          ],
          ip: 0,
          op: 1029,
          st: 0,
          ct: 1,
          bm: 0
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          parent: 1,
          tt: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.2, y: 1 },
                  o: { x: 0.8, y: 0 },
                  t: 90,
                  s: [396.6, 38.95, 0],
                  to: [4.715, -4.715, 0],
                  ti: [-4.715, 4.715, 0]
                },
                { t: 110, s: [424.89, 10.661, 0] }
              ],
              ix: 2,
              l: 2
            },
            a: { a: 0, k: [424.727, 20.727, 0], ix: 1, l: 2 },
            s: { a: 0, k: [32.237, 32.237, 100], ix: 6, l: 2 }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [155.453, 155.453], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.286274509804, 0.415686304429, 0.988235353956, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [424.727, 20.727], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 }
                }
              ],
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              hd: false
            }
          ],
          ip: 0,
          op: 1029,
          st: 0,
          ct: 1,
          bm: 0
        },
        {
          ddd: 0,
          ind: 10,
          ty: 4,
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 97,
                  s: [399.056, 33.718, 0],
                  to: [-0.384, 0.877, 0],
                  ti: [0.384, -0.877, 0]
                },
                { t: 117, s: [396.753, 38.981, 0] }
              ],
              ix: 2,
              l: 2
            },
            a: { a: 0, k: [396.753, 38.981, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.232, 0.038],
                        [3.738, 3.728],
                        [0.837, 5.213],
                        [-0.067, 0.227],
                        [-0.159, 0.175],
                        [-0.219, 0.088],
                        [-0.236, -0.015],
                        [-0.842, -11.834],
                        [0.088, -0.218],
                        [0.175, -0.158],
                        [0.226, -0.066]
                      ],
                      o: [
                        [-5.215, -0.824],
                        [-3.737, -3.729],
                        [-0.038, -0.233],
                        [0.067, -0.226],
                        [0.159, -0.175],
                        [0.219, -0.089],
                        [11.748, 0.824],
                        [0.016, 0.234],
                        [-0.088, 0.218],
                        [-0.174, 0.158],
                        [-0.225, 0.066]
                      ],
                      v: [
                        [9.511, 11.279],
                        [-4.263, 4.275],
                        [-11.299, -9.481],
                        [-11.256, -10.18],
                        [-10.913, -10.79],
                        [-10.339, -11.19],
                        [-9.648, -11.302],
                        [11.321, 9.636],
                        [11.211, 10.324],
                        [10.812, 10.895],
                        [10.206, 11.236]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.286274509804, 0.415686304429, 0.988235353956, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [383.369, 52.398], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 }
                }
              ],
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              hd: false
            }
          ],
          ip: 0,
          op: 1029,
          st: 29,
          ct: 1,
          bm: 0
        },
        {
          ddd: 0,
          ind: 11,
          ty: 4,
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 97,
                  s: [391.983, 33.553, 0],
                  to: [0.795, 0.905, 0],
                  ti: [-0.795, -0.905, 0]
                },
                { t: 117, s: [396.753, 38.981, 0] }
              ],
              ix: 2,
              l: 2
            },
            a: { a: 0, k: [396.753, 38.981, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.04, -0.234],
                        [3.738, -3.731],
                        [5.216, -0.82],
                        [0.227, 0.067],
                        [0.175, 0.159],
                        [0.088, 0.22],
                        [-0.017, 0.236],
                        [-11.839, 0.828],
                        [-0.22, -0.088],
                        [-0.16, -0.176],
                        [-0.067, -0.228]
                      ],
                      o: [
                        [-0.831, 5.215],
                        [-3.738, 3.73],
                        [-0.234, 0.04],
                        [-0.227, -0.066],
                        [-0.176, -0.159],
                        [-0.088, -0.219],
                        [0.837, -11.834],
                        [0.237, -0.017],
                        [0.221, 0.088],
                        [0.16, 0.176],
                        [0.066, 0.228]
                      ],
                      v: [
                        [11.302, -9.481],
                        [4.273, 4.282],
                        [-9.503, 11.283],
                        [-10.205, 11.243],
                        [-10.816, 10.901],
                        [-11.217, 10.324],
                        [-11.325, 9.631],
                        [9.644, -11.306],
                        [10.34, -11.198],
                        [10.918, -10.797],
                        [11.262, -10.183]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.286274509804, 0.415686304429, 0.988235353956, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [410.13, 52.394], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 }
                }
              ],
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              hd: false
            }
          ],
          ip: 0,
          op: 1029,
          st: 29,
          ct: 1,
          bm: 0
        },
        {
          ddd: 0,
          ind: 12,
          ty: 4,
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 97,
                  s: [391.983, 40.79, 0],
                  to: [0.795, -0.302, 0],
                  ti: [-0.795, 0.302, 0]
                },
                { t: 117, s: [396.753, 38.981, 0] }
              ],
              ix: 2,
              l: 2
            },
            a: { a: 0, k: [396.753, 38.981, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.233, -0.038],
                        [-3.744, -3.753],
                        [-0.814, -5.238],
                        [0.067, -0.227],
                        [0.159, -0.176],
                        [0.219, -0.088],
                        [0.236, 0.017],
                        [0.796, 11.803],
                        [-0.087, 0.22],
                        [-0.175, 0.16],
                        [-0.227, 0.067]
                      ],
                      o: [
                        [5.236, 0.827],
                        [3.744, 3.753],
                        [0.039, 0.233],
                        [-0.067, 0.227],
                        [-0.159, 0.175],
                        [-0.22, 0.088],
                        [-11.88, -0.823],
                        [-0.018, -0.236],
                        [0.088, -0.22],
                        [0.174, -0.16],
                        [0.227, -0.068]
                      ],
                      v: [
                        [-9.521, -11.341],
                        [4.298, -4.294],
                        [11.311, 9.542],
                        [11.269, 10.241],
                        [10.927, 10.853],
                        [10.352, 11.253],
                        [9.659, 11.362],
                        [-11.332, -9.68],
                        [-11.227, -10.372],
                        [-10.83, -10.949],
                        [-10.221, -11.295]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.286274509804, 0.415686304429, 0.988235353956, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [410.143, 25.633], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 }
                }
              ],
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              hd: false
            }
          ],
          ip: 0,
          op: 1029,
          st: 29,
          ct: 1,
          bm: 0
        },
        {
          ddd: 0,
          ind: 13,
          ty: 4,
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 97,
                  s: [399.22, 40.626, 0],
                  to: [-0.411, -0.274, 0],
                  ti: [0.411, 0.274, 0]
                },
                { t: 117, s: [396.753, 38.981, 0] }
              ],
              ix: 2,
              l: 2
            },
            a: { a: 0, k: [396.753, 38.981, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.017, -0.236],
                        [11.793, -0.824],
                        [0.219, 0.088],
                        [0.159, 0.175],
                        [0.066, 0.227],
                        [-0.039, 0.234],
                        [-3.744, 3.753],
                        [-5.237, 0.823],
                        [-0.227, -0.067],
                        [-0.175, -0.16],
                        [-0.088, -0.22]
                      ],
                      o: [
                        [-0.805, 11.802],
                        [-0.236, 0.016],
                        [-0.22, -0.089],
                        [-0.159, -0.175],
                        [-0.067, -0.227],
                        [0.811, -5.239],
                        [3.745, -3.753],
                        [0.234, -0.038],
                        [0.227, 0.067],
                        [0.175, 0.159],
                        [0.088, 0.219]
                      ],
                      v: [
                        [11.335, -9.682],
                        [-9.661, 11.361],
                        [-10.353, 11.251],
                        [-10.929, 10.85],
                        [-11.271, 10.239],
                        [-11.313, 9.539],
                        [-4.303, -4.298],
                        [9.519, -11.339],
                        [10.219, -11.295],
                        [10.829, -10.949],
                        [11.228, -10.374]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.286274509804, 0.415686304429, 0.988235353956, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [383.365, 25.621], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 }
                }
              ],
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              hd: false
            }
          ],
          ip: 0,
          op: 1029,
          st: 29,
          ct: 1,
          bm: 0
        }
      ]
    }
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 3,
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0], y: [1] },
              o: { x: [0.326], y: [0] },
              t: 0,
              s: [0]
            },
            {
              i: { x: [0.6], y: [1] },
              o: { x: [0.98], y: [0] },
              t: 23,
              s: [180]
            },
            {
              i: { x: [0.012], y: [1] },
              o: { x: [0.421], y: [0] },
              t: 46,
              s: [360]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.983], y: [0] },
              t: 69,
              s: [540]
            },
            { t: 93, s: [720] }
          ],
          ix: 10
        },
        p: { a: 0, k: [100, 100, 0], ix: 2, l: 2 },
        a: { a: 0, k: [50, 50, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      ip: 0,
      op: 1029,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      parent: 1,
      td: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 171.421, ix: 10 },
        p: { a: 0, k: [50.713, 49.712, 0], ix: 2, l: 2 },
        a: { a: 0, k: [-1.204, 3.394, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 79,
              s: [385, 385, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 80,
              s: [372, 372, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 81,
              s: [359.5, 359.5, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 82,
              s: [350, 350, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 83,
              s: [343, 343, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 84,
              s: [333, 333, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 85,
              s: [326, 326, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 86,
              s: [307, 307, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 87,
              s: [291, 291, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 88,
              s: [263, 263, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 89,
              s: [237, 237, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 90,
              s: [180, 180, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 91,
              s: [127, 127, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 92,
              s: [89, 89, 100]
            },
            { t: 93, s: [62, 62, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [40.504, 40.504], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.160784313725, 0.16862745098, 0.180392156863, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-1.204, 3.394], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 70,
      op: 94,
      st: 70,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 0,
      parent: 1,
      tt: 1,
      refId: 'comp_0',
      sr: 1.5,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [-376, 30, 0], ix: 2, l: 2 },
        a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      w: 1920,
      h: 1080,
      ip: 70,
      op: 94,
      st: -60,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      parent: 1,
      td: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 171.421, ix: 10 },
        p: { a: 0, k: [50.713, 49.712, 0], ix: 2, l: 2 },
        a: { a: 0, k: [-1.204, 3.394, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 48,
              s: [100, 100, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 49,
              s: [139, 139, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 50,
              s: [191, 191, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 51,
              s: [257, 257, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 52,
              s: [296, 296, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 53,
              s: [326, 326, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 54,
              s: [333, 333, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 55,
              s: [316, 316, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 56,
              s: [329, 329, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 57,
              s: [332, 332, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 58,
              s: [339, 339, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 59,
              s: [349, 349, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 60,
              s: [384, 384, 100]
            },
            { t: 61, s: [444, 444, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [40.504, 40.504], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.160784313725, 0.16862745098, 0.180392156863, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-1.204, 3.394], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 47,
      op: 70,
      st: 47,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 5,
      ty: 0,
      parent: 1,
      tt: 1,
      refId: 'comp_1',
      sr: 1.5,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [-376, 30, 0], ix: 2, l: 2 },
        a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      w: 1920,
      h: 1080,
      ip: 47,
      op: 70,
      st: -100,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      parent: 1,
      td: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 171.421, ix: 10 },
        p: { a: 0, k: [50.713, 49.712, 0], ix: 2, l: 2 },
        a: { a: 0, k: [-1.204, 3.394, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 32,
              s: [385, 385, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 33,
              s: [372, 372, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 34,
              s: [359.5, 359.5, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 35,
              s: [350, 350, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 36,
              s: [343, 343, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 37,
              s: [333, 333, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 38,
              s: [326, 326, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 39,
              s: [307, 307, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 40,
              s: [291, 291, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 41,
              s: [263, 263, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 42,
              s: [237, 237, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 43,
              s: [180, 180, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 44,
              s: [127, 127, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 45,
              s: [89, 89, 100]
            },
            { t: 46, s: [62, 62, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [40.504, 40.504], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.160784313725, 0.16862745098, 0.180392156863, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-1.204, 3.394], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 23,
      op: 47,
      st: 23,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 7,
      ty: 0,
      parent: 1,
      tt: 1,
      refId: 'comp_0',
      sr: 1.5,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [-376, 30, 0], ix: 2, l: 2 },
        a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      w: 1920,
      h: 1080,
      ip: 23,
      op: 47,
      st: -107,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      parent: 1,
      td: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 171.421, ix: 10 },
        p: { a: 0, k: [50.713, 49.712, 0], ix: 2, l: 2 },
        a: { a: 0, k: [-1.204, 3.394, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 1,
              s: [100, 100, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 2,
              s: [139, 139, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 3,
              s: [191, 191, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 4,
              s: [257, 257, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 5,
              s: [296, 296, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 6,
              s: [326, 326, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 7,
              s: [333, 333, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 8,
              s: [316, 316, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 9,
              s: [329, 329, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 10,
              s: [332, 332, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 11,
              s: [339, 339, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 12,
              s: [349, 349, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 13,
              s: [384, 384, 100]
            },
            { t: 14, s: [444, 444, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [40.504, 40.504], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.160784313725, 0.16862745098, 0.180392156863, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-1.204, 3.394], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 0,
      op: 23,
      st: 0,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 9,
      ty: 0,
      parent: 1,
      tt: 1,
      refId: 'comp_1',
      sr: 1.5,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [-376, 30, 0], ix: 2, l: 2 },
        a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      w: 1920,
      h: 1080,
      ip: 0,
      op: 23,
      st: -147,
      ct: 1,
      bm: 0
    }
  ],
  markers: []
};
